import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import isEmpty from 'lodash/isEmpty'
import _ from 'lodash'
import capitalize from 'lodash/capitalize'

import ComponentDescription from '../Common/ComponentDescription'
import { ComponentDetailsContainer } from './ComponentDetails'
import { DeviceDetailsContainer } from './DeviceDetails'
import { HarnessDetailsContainer } from './HarnessDetails'
import { OtherDetailsContainer } from './OtherDetails'
import MustHave from '../../containers/MustHave'
import {
  CIRCUIT_CATEGORY_HARNESS,
  CIRCUIT_CATEGORY_CONNECTOR,
  CIRCUIT_CATEGORY_INLINE,
  CIRCUIT_CATEGORY_OTHER,
  COMPONENT_CATEGORY_INLINE,
  ANNOTATION_MAX_LENGTH,
} from '../../constants'
import {
  guessComponentOrHarnessName,
  isEdge,
  isNode,
} from '../../helpers/element'
import { ArrowLeftOutlined } from '@ant-design/icons'
import ModalFullScreen from '../Modal/ModalFullScreen'
import { Layout, Row, Col, Button, Table, Flex } from 'antd'
import { formatTimestamp, dtcItemKey } from '../../helpers/utils'

const DisplayMode = Object.freeze({
  DISPLAY_TABLE: Symbol('DISPLAY_TABLE'),
  DISPLAY_HARNESS_DETAILS: Symbol('DISPLAY_HARNESS_DETAILS'),
  DISPLAY_CONNECTOR_DETAILS: Symbol('DISPLAY_CONNECTOR_DETAILS'),
  DISPLAY_OTHER_DETAILS: Symbol('DISPLAY_OTHER_DETAILS'),
  DISPLAY_DEVICE_DETAILS: Symbol('DISPLAY_DEVICE_DETAILS'),
})

const MAX_NOTES_LEN = 100

export class RepairHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleCommentExpansion: {},
      toggleDtcExpansion: {},
      selectedDtcs: null,
      rowItem: null,
      displayMode: DisplayMode.DISPLAY_TABLE,
      components: [],
    }
  }

  resetState = () => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_TABLE,
      rowItem: null,
      toggleCommentExpansion: {},
      toggleDtcExpansion: {},
      selectedCategory: null,
      selectedElement: null,
      selectedReason: null,
      selectedDtcs: null,
    })
  }

  toggleMoreOrLess(idx, key, booleanMap) {
    const newState = {}
    const mapObj = _.merge({}, booleanMap)
    mapObj[idx] = !booleanMap[idx]
    newState[key] = mapObj
    this.setState(newState)
  }

  getCircuitCategory(item) {
    if (isEmpty(item)) {
      return null
    }

    return this.props.circuitCategories.find((category) => {
      if (!isEdge(item) && !isNode(item)) {
        return category.id === CIRCUIT_CATEGORY_OTHER
      }
      if (isEdge(item)) {
        return category.id === CIRCUIT_CATEGORY_HARNESS
      }
      const componentCategory =
        item.category === COMPONENT_CATEGORY_INLINE
          ? COMPONENT_CATEGORY_INLINE
          : CIRCUIT_CATEGORY_CONNECTOR
      return category.id === componentCategory
    })
  }

  getComponentsForSelectedCircuitCategory() {
    const selectedCategoryId =
      this.state.selectedCategory && this.state.selectedCategory.id
    return this.getComponentsForCircuitCategory(selectedCategoryId)
  }

  getComponentsForCircuitCategory(categoryId) {
    if (!categoryId) {
      return []
    }

    const componentsForModelFn = (component) => ({
      label: `${guessComponentOrHarnessName(component)}`,
      description: component.description,
      value: component.id,
      id: component.id,
    })

    const harnessesForModelFn = (harness) => ({
      label: `${harness.id}`,
      value: harness.id,
      id: harness.id,
    })

    switch (categoryId) {
      case CIRCUIT_CATEGORY_CONNECTOR:
        const componentsForModel = this.props.componentsForModel.filter(
          (component) => component.category !== COMPONENT_CATEGORY_INLINE,
        )
        return componentsForModel.map(componentsForModelFn)
      case CIRCUIT_CATEGORY_INLINE:
        const inlineComponentsForModel = this.props.componentsForModel.filter(
          (component) => component.category === COMPONENT_CATEGORY_INLINE,
        )
        return inlineComponentsForModel.map(componentsForModelFn)
      case CIRCUIT_CATEGORY_HARNESS:
        return this.props.harnessesForModel.map(harnessesForModelFn)
      case CIRCUIT_CATEGORY_OTHER:
        return []
      default:
        throw new Error('unsupported circuit category')
    }
  }

  getFaultsFromCircuitCategory(categoryId) {
    const { component, inline, harness, other } = this.props.faults
    switch (categoryId) {
      case CIRCUIT_CATEGORY_CONNECTOR:
        return component
      case CIRCUIT_CATEGORY_INLINE:
        return inline
      case CIRCUIT_CATEGORY_HARNESS:
        return harness
      case CIRCUIT_CATEGORY_OTHER:
        return other
      default:
        throw new Error('unsupported circuit category')
    }
  }

  getDtcValues(element) {
    let elementDtcs
    if (this.state.selectedDtcs) {
      return this.state.selectedDtcs
    }
    if (this.state.rowItem && this.state.rowItem.id) {
      elementDtcs = this.getDtcsForElement(element)
    } else {
      elementDtcs = this.props.dtcs
    }
    return elementDtcs.map((dtc) => dtc.dtc_code)
  }

  getDtcOptions(element) {
    return this.props.dtcs.map((dtc) => ({
      value: dtc.dtc_code,
      label: dtcItemKey(dtc.module, dtc),
    }))
  }

  getCircuitCategories = (item) => {
    if (isEmpty(item)) {
      return this.props.circuitCategories
    }

    const isComponent = isNode(item) || item.type === 'component'
    const isHarness = isEdge(item) || item.type === 'harness'

    return this.props.circuitCategories.filter(
      (category) =>
        (isHarness && category.id === CIRCUIT_CATEGORY_HARNESS) ||
        (isComponent &&
          (category.id === CIRCUIT_CATEGORY_CONNECTOR ||
            category.id === CIRCUIT_CATEGORY_INLINE)) ||
        (!isHarness && !isComponent && category.id === CIRCUIT_CATEGORY_OTHER),
    )
  }

  getDtcsForElement(element) {
    const elementDtcCodes = new Set(_.map(element.dtcs, 'dtc'))
    return this.props.dtcs.filter((dtc) => elementDtcCodes.has(dtc.dtc_code))
  }

  onCircuitCategoryChange(selection) {
    this.setState({
      selectedCategory: selection,
      faults: this.getFaultsFromCircuitCategory(selection.id),
      selectedElement: null,
      selectedReason: null,
    })
    this.props.resetFields(['component', 'reason'])
  }

  onDtcChange(selectedDtcs) {
    this.setState({
      selectedDtcs,
    })
  }

  onReasonChange = (selectedReason) => {
    this.setState({
      selectedReason,
    })
  }

  closeModal = () => {
    this.resetState()
    this.props.onClose()
  }

  addNewFault = (item) => {
    this.hideAddOptions()
    const hasNewItems = this.props.repairHistory.filter(
      (item) => !item.id,
    ).length
    if (hasNewItems) {
      return false
    }
    this.resetState()
    // reset form to initial values
    this.props.reset()
    this.props.addRepairHistoryItem(item)
  }

  addDeviceFault = () => {
    this.hideAddOptions()
    this.setState({
      displayMode: DisplayMode.DISPLAY_DEVICE_DETAILS,
      rowItem: {},
    })
  }

  deleteItem = (item, idx) => {
    this.resetState()
    this.props.onDeleteHistoryItem(item.id, item.make_model_id, item.vin)
  }

  editHarness = (item, idx) => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_HARNESS_DETAILS,
      rowItem: item,
    })
  }
  editComponent = (item, idx) => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_CONNECTOR_DETAILS,
      rowItem: item,
    })
  }
  editOther = (item, idx) => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_OTHER_DETAILS,
      rowItem: item,
    })
  }

  editDevice = (item, idx) => {
    this.setState({
      displayMode: DisplayMode.DISPLAY_DEVICE_DETAILS,
      rowItem: item,
    })
  }

  dtcFormatter = (row, idx) => {
    const newContent = this.getDtcsForElement(row)
      .map((dtc) => dtcItemKey(dtc.module, dtc))
      .join(', ')
    return this.displayTruncated(
      newContent,
      !!this.state.toggleDtcExpansion[idx],
      () =>
        this.toggleMoreOrLess(
          idx,
          'toggleDtcExpansion',
          this.state.toggleDtcExpansion,
        ),
    )
  }

  displayTruncated = (content, isExpanded, toggleCallback) => {
    if (content && content.length > ANNOTATION_MAX_LENGTH) {
      if (isExpanded) {
        return (
          <span>
            {content}{' '}
            <a onClick={toggleCallback}>
              <FormattedMessage id="forms.showLess" />
            </a>
          </span>
        )
      } else {
        const truncatedContent = content.substr(0, ANNOTATION_MAX_LENGTH)
        return (
          <span>
            {truncatedContent}{' '}
            <a onClick={toggleCallback}>
              <FormattedMessage id="forms.showMore" />
            </a>
          </span>
        )
      }
    }
    return content
  }

  delete = (obj, idx) => {
    this.deleteItem(obj, idx)
  }

  edit = (obj, idx) => {
    const editHandle = {
      harness: this.editHarness,
      component: this.editComponent,
      device: this.editDevice,
      other: this.editOther,
    }
    editHandle[obj.type](obj, idx)
  }

  render() {
    const { header, content } = this.renderViews()
    return (
      <ModalFullScreen
        HeaderProp={header}
        ContentProp={content}
        isOpen={this.props.isOpen}
        dataTestIdContainer={'repair-history-modal'}
        dataTestIdHeader={'repair-history-modal-header'}
        dataTestIdContent={'repair-history-modal-content'}
      />
    )
  }

  renderViews() {
    switch (this.state.displayMode) {
      case DisplayMode.DISPLAY_TABLE:
        return this.renderTable()
      case DisplayMode.DISPLAY_CONNECTOR_DETAILS:
        return this.renderComponentDetails()
      case DisplayMode.DISPLAY_HARNESS_DETAILS:
        return this.renderHarnessDetails()
      case DisplayMode.DISPLAY_OTHER_DETAILS:
        return this.renderOthersDetails()
      case DisplayMode.DISPLAY_DEVICE_DETAILS:
        return this.renderDeviceDetails()
      default:
        return null
    }
  }

  renderHarnessDetails() {
    const body = {
      header: () => {
        return (
          <Row>
            <Col span={2}>
              <Button icon={<ArrowLeftOutlined />} onClick={this.resetState}>
                <FormattedMessage id="generic.back" />
              </Button>
            </Col>
            <Col span={22} className="modal-title-container">
              <FormattedMessage id="repairHistory.titles.harnessHistory" />
            </Col>
          </Row>
        )
      },
      content: () => {
        return (
          <HarnessDetailsContainer
            item={this.state.rowItem}
            onClose={() => this.resetState()}
          />
        )
      },
    }
    return body
  }

  renderComponentDetails() {
    const body = {
      header: () => {
        return (
          <Row>
            <Col span={2}>
              <Button icon={<ArrowLeftOutlined />} onClick={this.resetState}>
                <FormattedMessage id="generic.back" />
              </Button>
            </Col>
            <Col span={22} className="modal-title-container">
              <FormattedMessage id="repairHistory.titles.componentHistory" />
            </Col>
          </Row>
        )
      },
      content: () => {
        return (
          <ComponentDetailsContainer
            item={this.state.rowItem}
            onClose={() => this.resetState()}
          />
        )
      },
    }
    return body
  }

  renderOthersDetails() {
    const body = {
      header: () => {
        return (
          <Row>
            <Col span={2}>
              <Button icon={<ArrowLeftOutlined />} onClick={this.resetState}>
                <FormattedMessage id="generic.back" />
              </Button>
            </Col>
            <Col span={22} className="modal-title-container">
              <FormattedMessage id="repairHistory.titles.otherHistory" />
            </Col>
          </Row>
        )
      },
      content: () => {
        return (
          <OtherDetailsContainer
            item={this.state.rowItem}
            onClose={() => this.resetState()}
          />
        )
      },
    }
    return body
  }

  renderDeviceDetails() {
    const body = {
      header: () => {
        return (
          <Row>
            <Col span={2}>
              <Button icon={<ArrowLeftOutlined />} onClick={this.resetState}>
                <FormattedMessage id="generic.back" />
              </Button>
            </Col>
            <Col span={22} className="modal-title-container">
              <FormattedMessage id="repairHistory.titles.deviceHistory" />
            </Col>
          </Row>
        )
      },
      content: () => {
        return (
          <DeviceDetailsContainer
            item={this.state.rowItem}
            onClose={() => this.resetState()}
          />
        )
      },
    }
    return body
  }

  renderTable() {
    const columns = [
      {
        title: <FormattedMessage id="repairHistory.columns.date" />,
        render: (text, record) =>
          formatTimestamp(
            this.props.intl.formatMessage({ id: 'format.timestamp' }),
            record.created_at,
          ),
      },
      {
        title: <FormattedMessage id="repairHistory.columns.category" />,
        render: (text, record) => capitalize(record.type),
      },
      {
        title: <FormattedMessage id="repairHistory.columns.name" />,
        render: (text, record) =>
          record.type === 'other' ? (
            'N/A'
          ) : (
            <ComponentDescription
              name={guessComponentOrHarnessName(record)}
              level={record.harness_level}
              description={record.description}
            />
          ),
      },
      {
        title: <FormattedMessage id="repairHistory.columns.dtcs" />,
        render: (text, record, index) => this.dtcFormatter(record, index),
      },
      {
        title: <FormattedMessage id="repairHistory.columns.notes" />,
        dataIndex: ['annotation'],
        ellipsis: true,
      },
      {
        title: <FormattedMessage id="repairHistory.columns.faultType" />,
        dataIndex: ['reason'],
      },
      {
        title: <FormattedMessage id="repairHistory.columns.userId" />,
        dataIndex: ['created_by_id'],
      },
      {
        title: '',
        render: (text, record, index) => (
          <Button onClick={() => this.edit(record, index)} type="primary">
            <FormattedMessage id="forms.edit" />
          </Button>
        ),
      },
      {
        title: '',
        render: (text, record, index) => (
          <Button onClick={() => this.delete(record, index)} danger>
            <FormattedMessage id="forms.delete" />
          </Button>
        ),
      },
    ]

    const body = {
      header: () => {
        return (
          <Row>
            <Col span={20}>
              <Row>
                <Col span={3}>
                  <Button
                    icon={<ArrowLeftOutlined />}
                    onClick={this.closeModal}
                  >
                    <FormattedMessage id="generic.back" />
                  </Button>
                </Col>
                <Col span={21} className="modal-title-container">
                  <FormattedMessage id="repairHistory.titles.general" />
                </Col>
              </Row>
            </Col>
            <Col span={4}>
              <Flex vertical={false} justify="flex-end" align="center">
                <MustHave permission="component-fault:create">
                  <Button
                    onClick={this.props.openAddFaultModal}
                    data-testid="add-fault-button"
                  >
                    <FormattedMessage id="repairHistory.titles.addFault" />
                  </Button>
                </MustHave>
              </Flex>
            </Col>
          </Row>
        )
      },
      content: () => {
        return (
          <Table
            id="repair-history-view"
            pagination={false}
            scroll={{
              y: 'calc(100vh - 114px)', // 114 is 64px (default height of the header) and 50 px (height of the table header)
            }}
            columns={columns}
            dataSource={this.props.repairHistory}
            rowKey={(record) => record.id}
          />
        )
      },
    }

    return body
  }
}

export default injectIntl(RepairHistory)
