import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import max from 'lodash/max'

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Flex, Tooltip } from 'antd'
import { UpOutlined, DownOutlined, MessageOutlined } from '@ant-design/icons'

import { capitalizeAllWords, formatTimestamp } from '../helpers/utils'

const getLastTimeApplied = (faultsDates) => {
  const lastTimeApplied = max(faultsDates)
  return lastTimeApplied
}

const ComponentFaultHistoryItem = injectIntl(({ fault, intl, tz }) => {
  const { vin, created_at: createdAt, annotation } = fault
  const noCommentsText = intl.formatMessage({
    id: 'tracer.componentHistory.noComments',
  })
  const formatString = intl.formatMessage({ id: 'format.timestamp' })
  const formattedDate = formatTimestamp(formatString, createdAt)

  return (
    <Flex className="history-item" align="center">
      <div className="vin">{vin}</div>
      <div className="last-time-applied">{formattedDate}</div>
      <Tooltip trigger={'hover'} title={annotation} zIndex={6}>
        <div className="annotation">{annotation || noCommentsText}</div>
      </Tooltip>
    </Flex>
  )
})

const ComponentFaultHistoryGroup = injectIntl((props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { faultType, faultsItems, intl, tz } = props
  const annotationsCount = filter(
    faultsItems,
    (faultItem) => !isEmpty(faultItem.annotation),
  ).length
  const formatString = intl.formatMessage({ id: 'format.timestamp' })
  const faultsDates = map(faultsItems, 'created_at')
  const lastTimeApplied = getLastTimeApplied(faultsDates)
  const formattedLastTimeApplied = formatTimestamp(
    formatString,
    lastTimeApplied,
  )

  return (
    <Flex className="component-fault-history-group" vertical={true}>
      <Flex
        className="header-container"
        justify="center"
        align="center"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="arrow">
          {isExpanded ? <DownOutlined /> : <UpOutlined />}
        </div>
        <div className="fault-type-container">
          <span className="fault-type-name">
            {capitalizeAllWords(faultType)}
          </span>{' '}
          ({faultsItems.length})
        </div>
        <div className="cell">{formattedLastTimeApplied}</div>
        <Flex
          className="annotation-count-container"
          justify="flex-start"
          align="center"
          gap="large"
        >
          {annotationsCount === 0 && (
            <span className="no-comments">
              <FormattedMessage id="tracer.componentHistory.noComments" />
            </span>
          )}
          {annotationsCount > 0 && (
            <div>
              <MessageOutlined />
              <span className="annotation-count">{annotationsCount}</span>
            </div>
          )}
        </Flex>
      </Flex>
      {isExpanded &&
        map(faultsItems, (faultItem) => (
          <ComponentFaultHistoryItem
            fault={faultItem}
            key={faultItem.id}
            tz={tz}
          />
        ))}
    </Flex>
  )
})

const mapStateToProps = (state, ownProps) => {
  return {
    tz: state.user.businessTimezone,
  }
}

const ComponentFaultsHistoryList = (props) => {
  const { componentFaultsHistory, tz } = props
  return (
    <div className="component-history-popover">
      {map(componentFaultsHistory, (faultsItems, faultType) => (
        <ComponentFaultHistoryGroup
          faultType={faultType}
          faultsItems={faultsItems}
          key={faultType}
          tz={tz}
        />
      ))}
    </div>
  )
}

export default connect(mapStateToProps)(ComponentFaultsHistoryList)
