import axios from 'axios'

const api = axios.create({
  baseURL: '/tracer',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

const tokenBasedApi = axios.create({
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
})

export function apiGetBlockDiagramGraph({ vin, subsystemId }) {
  const params = new URLSearchParams({ vin, subsystem_id: subsystemId })

  return api
    .get(`/block-diagram?${params.toString()}`)
    .then((response) => response.data)
}

export function apiGetDetailDiagramGraph({ vin, componentA, componentB }) {
  const params = new URLSearchParams({
    vin,
    component_a: componentA,
    component_b: componentB,
  })

  return api
    .get(`/block-diagram-detail?${params.toString()}`)
    .then((response) => response.data)
}

export function apiGetSubsystems({ vin }) {
  return api.get(`/subsystems/${vin}`).then((resp) => resp.data)
}

export function apiGetSubsystemsSearch({ vin, componentId }) {
  const params = new URLSearchParams({ component_id: componentId })
  return api
    .get(`/subsystems/${vin}/search?${params.toString()}`)
    .then((resp) => resp.data)
}

export function apiGetConnectorPinout({
  vin,
  componentId,
  neighborIds = null,
}) {
  const params = new URLSearchParams({ vin, component_id: componentId })

  if (neighborIds) params.set('neighbor_ids', neighborIds)

  return api
    .get(`/block-diagram-pinout?${params.toString()}`)
    .then((resp) => resp.data)
}

export function apiGetComponentNeighbors({ vin, componentId }) {
  const params = new URLSearchParams({ vin, component_id: componentId })
  return api
    .get(`/block-diagram-neighbors?${params.toString()}`)
    .then((resp) => resp.data)
}

export function apiGetTokenBasedBlockDiagramGraph({
  vin,
  authToken,
  componentIds,
}) {
  return tokenBasedApi
    .post(
      `/api/v1/block-diagram`,
      {
        vin: vin,
        component_ids: componentIds,
      },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      },
    )
    .then((response) => response.data)
}
