import { useQuery } from 'react-query'
import cytoscape from 'cytoscape'
import {
  apiGetComponentNeighbors,
  apiGetBlockDiagramGraph,
  apiGetDetailDiagramGraph,
  apiGetSubsystems,
  apiGetConnectorPinout,
  apiGetSubsystemsSearch,
  apiGetTokenBasedBlockDiagramGraph,
} from './api'
import { apiGetTerminology } from '../api/terminology'
import { locale } from '../Locale'
import { getSubsystemCodes, updateSubsystemDescription } from './utils'

export const BLOCK_DIAGRAM_GRAPH_KEY = 'block-diagram'

export const useBlockDiagram = ({ vin, subsystemId }) => {
  const {
    status,
    data: blockDiagramGraph,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [BLOCK_DIAGRAM_GRAPH_KEY, vin, subsystemId],
    async () => {
      const result = await apiGetBlockDiagramGraph({ vin, subsystemId })
      if (
        !!result &&
        !!result.data &&
        !!result.data.elements &&
        !!result.data.elements.nodes
      ) {
        const codes = result.data.elements.nodes.map((n) => n.data.componentId)
        const terms = await apiGetTerminology({ vin, language: locale, codes })
        result.data.elements.nodes.forEach((node, idx) => {
          const key = node.data.componentId
          if (!!terms && !!terms[key] && !!terms[key]['description']) {
            result.data.elements.nodes[idx].data.description =
              terms[key]['description']
          }
        })
      }
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!subsystemId && !!vin,
    },
  )

  const graph = cytoscape({
    headless: true,
  })
  graph.json(blockDiagramGraph)

  // All Connectors include duplicates which are used to build ports in graph.
  const allConnectors = graph.nodes((node) => node.data('parent'))

  // Remove duplicates from all connectors.
  const connectors = [
    ...allConnectors
      .reduce((connectors, connector) => {
        const connectorData = connector.data()
        if (connectors.has(connectorData.componentId)) {
          connectors.get(connectorData.componentId).ids.push(connector.id())
        } else {
          const { id, ...data } = connectorData
          connectors.set(connectorData.componentId, {
            ...data,
            ids: [connector.id()],
          })
        }
        return connectors
      }, new Map())
      .values(),
  ]

  const devices = graph
    .nodes((node) => node.data('isParent') === true)
    .map((node) => {
      const data = node.data()
      return {
        ...data,
      }
    })

  return {
    status,
    blockDiagramGraph,
    devices,
    connectors,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const DETAIL_DIAGRAM_GRAPH_KEY = 'block-diagram-detail'

export const useDetailDiagramGraph = ({ vin, componentA, componentB }) => {
  const {
    status,
    data: detailDiagramGraph,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [DETAIL_DIAGRAM_GRAPH_KEY, vin, componentA, componentB],
    async () => {
      const result = await apiGetDetailDiagramGraph({
        vin,
        componentA,
        componentB,
      })
      if (
        !!result &&
        !!result.data &&
        !!result.data.elements &&
        !!result.data.elements.nodes
      ) {
        const codes = result.data.elements.nodes.map((n) => {
          return n.data.componentId ? n.data.componentId : n.data.id
        })
        const terms = await apiGetTerminology({ vin, codes, language: locale })
        result.data.elements.nodes.forEach((n, idx) => {
          const key = n.data.componentId ? n.data.componentId : n.data.id
          if (!!key && !!terms[key] && !!terms[key]['description']) {
            result.data.elements.nodes[idx].data['description'] =
              terms[key]['description']
          }
        })
      }
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!componentA && !!componentB && !!vin,
    },
  )
  return {
    status,
    detailDiagramGraph,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const BLOCK_DIAGRAM_SUBSYSTEMS = 'block-diagram-subsytems'

export const useSubsystems = ({ vin }) => {
  const {
    status,
    data: subsystems,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [BLOCK_DIAGRAM_SUBSYSTEMS, vin],
    async () => {
      const result = await apiGetSubsystems({ vin })
      const groupedCodes = getSubsystemCodes(result.data)
      const data = await apiGetTerminology({
        vin,
        language: locale,
        codes: groupedCodes.codes,
      })
      const translatedData = updateSubsystemDescription(
        data,
        result.data,
        groupedCodes.groups,
      )
      return translatedData
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vin,
    },
  )

  return {
    status,
    subsystems,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const BLOCK_DIAGRAM_SUBSYSTEMS_SEARCH = 'block-diagram-subsytems-search'

export const useSearchSubsystems = ({ vin, componentId }) => {
  const {
    status,
    data: searchSubsystems,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [BLOCK_DIAGRAM_SUBSYSTEMS_SEARCH, vin, componentId],
    async () => {
      const result = await apiGetSubsystemsSearch({
        vin,
        componentId,
      })
      const groupedCodes = getSubsystemCodes(result.data)
      const data = await apiGetTerminology({
        vin,
        language: locale,
        codes: groupedCodes.codes,
      })
      const translatedData = updateSubsystemDescription(
        data,
        result.data,
        groupedCodes.groups,
      )
      return translatedData
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vin && !!componentId,
    },
  )

  return {
    status,
    searchSubsystems,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const BLOCK_DIAGRAM_CONNECTOR_PINOUT = 'block-diagram-connector-pinout'

export const useComponentPinout = ({ vin, componentId, neighborIds }) => {
  const {
    status,
    data: pinout,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
  } = useQuery(
    [BLOCK_DIAGRAM_CONNECTOR_PINOUT, vin, componentId, neighborIds],
    async () => {
      const result = await apiGetConnectorPinout({
        vin,
        componentId,
        neighborIds,
      })

      if (!!result && !!result.data) {
        const codes = result.data.map((d) => d.pin)
        const terms = await apiGetTerminology({ vin, codes, language: locale })
        result.data.forEach((d, idx) => {
          const key = d.pin
          if (!!key && !!terms && !!terms[key] && !!terms[key]['description']) {
            result.data[idx]['description'] = terms[key]['description']
          }
        })
      }
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vin && !!componentId && !!neighborIds,
    },
  )

  return {
    status,
    pinout,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
  }
}

export const BLOCK_DIAGRAM_CONNECTOR_FULL_PINOUT =
  'block-diagram-connector-full-pinout'

export const useComponentFullPinout = ({ vin, componentId }) => {
  const {
    status,
    data: pinout,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
    refetch,
  } = useQuery(
    [BLOCK_DIAGRAM_CONNECTOR_FULL_PINOUT, vin, componentId],
    async () => {
      const result = await apiGetConnectorPinout({ vin, componentId })
      if (!!result && !!result.data) {
        const codes = result.data.map((d) => d.pin)
        const terms = await apiGetTerminology({ vin, codes, language: locale })
        result.data.forEach((d, idx) => {
          const key = d.pin
          if (!!key && !!terms[key] && !!terms[key]['description']) {
            result.data[idx]['description'] = terms[key]['description']
          }
        })
      }
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vin && !!componentId,
    },
  )

  return {
    status,
    pinout,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
    refetch,
  }
}

export const BLOCK_DIAGRAM_COMPONENT_NEIGHBORS =
  'block-diagram-component-neighbors'

export const useComponentNeighbors = ({ vin, componentId }) => {
  const {
    status,
    data: neighbors,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
  } = useQuery(
    [BLOCK_DIAGRAM_COMPONENT_NEIGHBORS, vin, componentId],
    async () => {
      const result = await apiGetComponentNeighbors({ vin, componentId })
      if (!!result && !!result.data) {
        const codes = result.data.map((d) => d.componentId)
        const terms = await apiGetTerminology({ vin, codes, language: locale })
        result.data.forEach((d, idx) => {
          const key = d.componentId
          if (!!key && !!terms && !!terms[key] && !!terms[key]['description']) {
            result.data[idx]['description'] = terms[key]['description']
          }
        })
      }
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vin && !!componentId,
    },
  )

  return {
    status,
    neighbors,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    isIdle,
  }
}

export const usePreviewBlockDiagram = ({ vin, authToken, componentIds }) => {
  const {
    status,
    data: blockDiagramGraph,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [BLOCK_DIAGRAM_SUBSYSTEMS, vin],
    async () => {
      const result = await apiGetTokenBasedBlockDiagramGraph({
        vin,
        authToken,
        componentIds,
      })
      return result.data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!vin,
    },
  )

  return {
    status,
    blockDiagramGraph,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useParsePreviewParams = () => {
  const searchParams = new URLSearchParams(window.location.search)
  try {
    const { vin, authToken, componentIds } = JSON.parse(
      window.atob(searchParams.get('params')),
    )
    return {
      vin,
      authToken,
      componentIds,
    }
  } catch (error) {
    return {
      vin: '',
      authToken: '',
      componentIds: [],
    }
  }
}
