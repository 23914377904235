import React, { useEffect } from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { FormattedMessage, injectIntl } from 'react-intl'
import { formatTimestamp } from '../helpers/utils'
import { navigateToVehicleTest } from '../api/vehicle-tests-api'
import { VEHICLE_TEST_ORGANISATION_TYPE } from '../constants'
import { Table, Typography, Button, Flex } from 'antd'

const { Title } = Typography

const useVehicleTests = (testZones, timeAfterTest) => {
  const testZoneParams = testZones.map((zone) => ['test_zone', zone])
  const params = new URLSearchParams([
    ...testZoneParams,
    ['age[lte]', timeAfterTest.value],
    ['organisation_type', VEHICLE_TEST_ORGANISATION_TYPE.tester],
  ])

  return useQuery(
    ['vehicle-tests', testZones, timeAfterTest.value],
    async () => {
      const result = await axios.get('/tracer/vehicles/tests/', { params })
      if (
        result.data &&
        result.data.data &&
        result.data.data instanceof Array &&
        result.data.data.length > 0 &&
        result.data.data[0].sequence_number
      ) {
        return result.data.data.sort((a, b) => {
          return a.sequence_number.localeCompare(b.sequence_number)
        })
      }
      return result.data.data
    },
    {
      refetchInterval: 5000,
      enabled: !!testZones,
    },
  )
}

const ViewVehicleTestButton = ({ vehicleTestId }) => {
  return (
    <Button
      type="primary"
      onClick={(event) =>
        navigateToVehicleTest({
          vehicleTestId: vehicleTestId,
          newTab: false,
          navigatedFrom: 'production-line-dashboard',
          online: true,
        })
      }
    >
      <FormattedMessage id="productionLineDashboard.dashboardResults.view" />
    </Button>
  )
}

function DashboardResults({ intl, searchTerm }) {
  const { testZones, timeAfterTest } = searchTerm
  const { status, data, error, isFetching } = useVehicleTests(
    testZones,
    timeAfterTest,
  )

  const dateTimeFormat = intl.formatMessage({ id: 'format.dayAndTime' })

  const columns = React.useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'productionLineDashboard.resultsTable.sequence',
        }),
        dataIndex: 'sequence_number',
      },
      {
        title: intl.formatMessage({
          id: 'productionLineDashboard.resultsTable.vin',
        }),
        dataIndex: 'vin',
      },
      {
        title: intl.formatMessage({
          id: 'productionLineDashboard.resultsTable.date',
        }),
        render: (record) =>
          formatTimestamp(dateTimeFormat, record.test_timestamp),
      },
      {
        title: intl.formatMessage({
          id: 'productionLineDashboard.resultsTable.testZone',
        }),
        render: (record) =>
          record.test_zone.description
            ? record.test_zone.description
            : record.test_zone.specifier,
      },
      {
        title: '',
        dataIndex: 'id',
        render: (record) => <ViewVehicleTestButton vehicleTestId={record} />,
      },
    ],
    [],
  )

  if (testZones.length === 0) {
    return (
      <Title level={4}>
        <FormattedMessage id="productionLineDashboard.dashboardResults.helpMessage" />
      </Title>
    )
  }

  if (status === 'error') {
    return (
      <Flex vertical align="center">
        <Title level={4}>
          <FormattedMessage id="productionLineDashboard.dashboardResults.helpMessageError" />
        </Title>
        {isFetching ? (
          <div>
            <Title level={4}>
              <FormattedMessage id="productionLineDashboard.dashboardResults.refreshing" />
            </Title>
          </div>
        ) : null}
      </Flex>
    )
  }

  if (data && data.length > 0) {
    return (
      <Table
        columns={columns}
        dataSource={data}
        id="production-line-results"
        pagination={{
          pageSize: 9,
          hideOnSinglePage: true,
        }}
      />
    )
  }

  return (
    <Flex vertical align="center">
      <Title level={4}>
        <FormattedMessage id="productionLineDashboard.dashboardResults.helpMessageNoTestZone" />{' '}
        {testZones.map((zone) => `"${zone}"`).join(', ')}
      </Title>
      {isFetching ? (
        <Title level={4}>
          <FormattedMessage id="productionLineDashboard.dashboardResults.refreshing" />
        </Title>
      ) : null}
    </Flex>
  )
}

export default injectIntl(DashboardResults)
